.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #f69260;
  --secondary-color: #fc854a;
  --complimentary-color: #d6a4df;
  --contrast-color: #ffd342;
  --light-color: #f5cf52;
}

.container {
  background: transparent;
  height: 100vh;
  font-family: Montserrat, sans-serif;
}

nav a {
  /* font-size: 40px; */
  color: white;
  text-decoration: none;
  padding: 20px;
  text-align: center;
}

nav {
  position: fixed;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  background: var(--secondary-color);
  border-right: 2px solid #ffe4bd;
}

.p-mobile {
  opacity: 0;
}

section {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 0;
  /* opacity: 0; */
  transition: all ease-in 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

section h1 {
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
  opacity: 0;
}

/* Styles applied on trigger */
section:target {
  opacity: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

section:target h1 {
  opacity: 0;
  animation: 2s fadeIn forwards 0.5s;
}

#first {
  background: var(--primary-color);
}
#second {
  background: var(--complimentary-color);
}

#third {
  background: var(--contrast-color);
}

#fourth {
  background: var(--light-color);
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HOMEPAGE */
.homepage-section {
  width: 100%;
  height: 100%;
  background: #eaffae;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}



.wrapper {
  height: 100%;
  width: 50%;
  background-color: rgb(248, 152, 43);
  background-size: cover;
  overflow: hidden;
  color: #eaffae;
  position: absolute;
  top: 0;
  left: 0;
}

.sliding {
  position: absolute;
  top: 50%;
  left: -5px;
  color: rgb(248, 152, 43);
  white-space: nowrap;
  font-size: 16em;
  line-height: 220px;
  overflow: hidden;
  font-family: "Abel", sans-serif;
  letter-spacing: 2px;
  animation: animate 20s linear infinite;
}

.sliding2 {
  position: absolute;
  top: 50%;
  left: 0px;
  white-space: nowrap;
  font-size: 16em;
  line-height: 220px;
  overflow: hidden;
  letter-spacing: 2px;
  font-family: "Abel", sans-serif;
  animation: animate 20s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
/* Aboutme Page */

.p-mobile {
  position: relative;
  opacity: 0;
  left: -10px;
}

.about-container {
  background-image: url("https://res.cloudinary.com/doihe1pi6/image/upload/v1618961794/nico-mksmc-caH-ZLrisZA-unsplash_ovazv5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.h3-about {
  color: rgb(254, 255, 218);
  font-size: 30px;
  font-family: "Abel", sans-serif;
  margin-top: 10px;
  letter-spacing: 1px;
}

.about-main {
  /* font-family: 'Montserrat Alternates', sans-serif; */
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  max-width: 900px;
  margin-top: 60vh;
  color: rgb(254, 255, 218);
  font-size: 20px;
  /* border: 2px solid #ffe4bd; */
  padding: 40px;
  background-color: rgba(243, 156, 56, 0.59);
  border-radius: 20px;
}

.about-brand {
  margin: 0 auto;
  padding: 3vh;
  text-align: justify;
}

.me-img {
  width: 350px;
  height: auto;
  border-radius: 130px;
}

h1 {
  position: absolute;
  font-family: "Abel", sans-serif;
  font-style: italic;
  letter-spacing: 10px;
  top: 0;
  padding: 10px;
  width: 100%;
  height: 75px;
  background-color: rgb(255, 182, 46);
}

.home-name{
  height: 14vh;
}

.pronouns {
  font-size: 20px;
  position: absolute;
  top: 75px;
  font-family: "Quicksand", sans-serif;
  color: rgb(254, 255, 218);
}

h3 {
  padding: 20px;
}

.p-home {
  font-size: 2vw;
  text-align: center;
  letter-spacing: 2px;
  width: 45vw;
  margin: 0 auto;
}

h4 {
  padding: 3vh;
  font-family: "Abel", sans-serif;
  letter-spacing: 3px;
}

a {
  color: rgb(255, 102, 0);
}
/* PROJECTS */

.project-bg {
  background-image: url("https://res.cloudinary.com/doihe1pi6/image/upload/v1618961794/nico-mksmc-caH-ZLrisZA-unsplash_ovazv5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 140px;
  margin-top: 100px;
  max-width: 90%; 
  max-height: 650px;
}

.project {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 42vh;
  margin: 5px;
  overflow: hidden;
  border-radius: 10px;
}

.img1 {
  height: 45vh;
  width: 40vw;
  z-index: 1;
  margin-top: 25px;
}

.img2 {
  opacity: 0;
}

.app-descrip {
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 2;
  padding: 30px;
  font-size: 1vw;
  color: white;
  font-family: "Quicksand", sans-serif;
  background-color: rgba(255, 103, 2, 0.69);
  opacity: 0;
  border-radius: 10px;
}

.app-descrip:hover {
  position: absolute;
  width: 35.5vw;
  height: 33vh;
  z-index: 2;
  padding: 2.2vw;
  font-size: 1.3vw;
  color: white;
  font-family: "Quicksand", sans-serif;
  background-color: rgba(255, 103, 2, 0.69);
  opacity: 1;
  border-radius: 10px;
}

.project-title {
  margin-top: -20px;
  margin-bottom: 10px;
  font-size: 25px;
}

.technology {
  margin-bottom: 5vh;
}

.project-link {
  position: relative;
  top: 3vh;
  color: rgb(212, 124, 9);
  background-color: #ffe6bf;
  border-radius: 2.5vh;
  margin: 2.5vh .2vw;
  font-size: 17px;
  padding: .6vw;
  text-decoration: none;
}

/* CONTACT */

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  width: 85vh;
  height: 50vh;
  overflow: hidden;
  padding: 60px;
  color: #ffeaa4;
  background-image: url("https://res.cloudinary.com/doihe1pi6/image/upload/v1618961794/nico-mksmc-caH-ZLrisZA-unsplash_ovazv5.jpg");
  background-position: center;
  background-size: cover;
  /* background-color: rgba(255, 103, 2, 0.69); */
  border-radius: 20px;
}

.contact-top-row {
  display: flex;
  flex-direction: row;
}

.contact-bottom-row {
  display: flex;
  flex-direction: row;
}

.h3-contact {
  color: rgba(255, 103, 2);
  padding: 10px;
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
}

.a-contact {
  color: #ffeaa4;
}

.link-container {
  height: auto;
  width: auto;
  padding: 15px;
}

/* MOBILE SIZING */

@media only screen and (max-device-width: 480px) {
  nav {
    width: 30px;
  }

  nav a {
    font-size: 15px;
  }

  .p-mobile {
    position: relative;
    opacity: 1;
    left: -10px;
  }

  h1 {
    margin-left: 25px;
    height: 4vh;
  }

  .home-name {
    font-size: 40px;
    height: 14vh;  
  }
  .pronouns {
    position: absolute;
    top: 18vh;
  }

  .about-main {
    margin-top: 255vh;
    width: 280px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .me-img {
    width: 200px;
    height: auto;
  }

  .about-brand {
    position: relative;
    width: 230px;
    margin: 0 auto;
    left: -5vw;
  }

  .about-container {
    background-size: cover;
    background-repeat: repeat-x;
  }

  .p-home {
    font-size: 25px;
  }

  .project-bg{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-container {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project {
    width: 90vw;
    height: auto;
    object-fit: cover;
    margin: 5px;
  }

  .img1 {
    width: 90vw;
    height: auto;
  }

  .icon {
    opacity: 0;
  }

  .app-descrip-mobile {
    width: 80vw;
    height: auto;
    z-index: 2;
    padding: 30px;
    font-size: 20px;
    color: white;
    font-family: "Quicksand", sans-serif;
    background-color: rgba(255, 103, 2, 0.69);
    opacity: 1;
    border-radius: 10px;
  }

  .project-link {
    position: relative;
    top: 1vh;
    color: white;
    font-family: "Quicksand", sans-serif;
    background-color: rgba(255, 103, 2, 0.69);
    opacity: 1;
    border-radius: 10px;
  }

  .h3-contact {
    font-size: 20px;
  }

  .contact-container {
    max-width: 250px;
  }
}
